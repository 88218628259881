/*
* Admin Layout (cryptoon)
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:cryptoon
* Copyright 2022 Pixelwibes
*/
@mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin overflow($scroll) {
    overflow-y: auto;
    overflow-y: scroll;
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none;  // IE 10+

    &::-webkit-scrollbar { // WebKit 
        width: 0;
        //background-color: var(--color-200)
    }
    &::-webkit-scrollbar-thumb {
        //background: var(--color-200)
    }
}
//*******Custome Scroll*******/
@mixin c_overflow($scroll) {  
    overflow-y: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  // IE 10+
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
        width: 4px;
    }
    &:hover {
        &::-webkit-scrollbar-thumb{
            visibility: visible;
        }
        &::-webkit-scrollbar-track {
            visibility: visible;
        }    
    }
    
    // Track /
    &::-webkit-scrollbar-track {
        visibility: hidden;
        background: transparent;
    }    
    // Handle /
    &::-webkit-scrollbar-thumb {
        visibility: hidden;
        opacity: 0.2;
        background: var(--secondary-color);
        //background: linear-gradient(-45deg, var(--secondary-color), var(--primary-color));
        border-radius: 5px;
        &:hover{
            opacity: 1;
        }
    }
}