.loading__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-50%, -50%);
}
.loading__element {
  transform-origin: 50% 50%;
}
.loading__element:nth-child(1) {
  animation: shrink 3200ms  linear infinite;
  animation-delay: 200ms;
}
.loading__element:nth-child(2) {
  animation: shrink 1600ms linear infinite;
  animation-delay: 400ms;
}
.loading__element:nth-child(3) {
  animation: shrink 1600ms linear infinite;
  animation-delay: 600ms;
}
.loading__element:nth-child(4) {
  animation: shrink 1600ms linear infinite;
  animation-delay: 800ms;
}
.loading__element:nth-child(5) {
  animation: shrink 1600ms linear infinite;
  animation-delay: 1000ms;
}
.loading__element:nth-child(6) {
  animation: shrink 1600ms linear infinite;
  animation-delay: 1200ms;
}
.loading__element:nth-child(7) {
  animation: shrink 1600ms linear infinite;
  animation-delay: 1400ms;
}
.loading__element:nth-child(8) {
  animation: shrink 1600ms linear infinite;
  animation-delay: 1600ms;
}
@keyframes shrink {
  20% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}