@media (min-width: 992px) {
  .offcanvas {
    visibility: visible;
    position: relative;
    background: none;
    border: none;
    justify-content: end;
    color: red;
  }
}

.toast_message {
  z-index: 999999 !important;
}

@media (max-width: 992px) {
  .offcanvas {
    width: 250px !important;
  }

  .hesadersec .navbar-nav li {
    margin-bottom: 10px;
  }

  .offcanvas-start-lg {
    top: 0;
    left: 0;
    border-right: 1px solid var(--border-color);
    transform: translateX(-100%);
  }
}

ul li {
  list-style: none;
}

.hesadersec nav.navbar.navbar-expand-lg {
  background-color: var(--header-color) !important;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 75px;
}

.hesadersec .navbar-nav li a {
  color: var(--color-900) !important;
  font-size: 17px;
}

.hesadersec .navbar-nav li {
  padding: 0 9px;
}

.bannercontent .btn {
  background-color: var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  text-align: center;
}

.bannersec .carousel-control-prev {
  color: var(--color-900) !important;
}

.bannercontent .btn:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.bounce-animate {
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@keyframes float-bob {

  0%,
  100% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(20px);
  }
}

.banner-ico {
  position: relative;
}

.banner-ico image img {
  width: 100%;
}

.banner-ico .shape-image1 {
  position: absolute;
  right: 155px;
  top: 27px;
}

.ico-crypto .card {
  border: none;
  padding: 15px 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  background-color: var(--card-color);
}

.cryptofunds img {
  width: 100%;
  max-width: 80px;
}

.bannersec img {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: block;
}

.bannersec .shape-image1 img {
  width: 100%;
  max-width: 300px !important;
}

.about-section img {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: block;
}

.readbtn {
  background-color: #f4a012;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  max-width: 150px;
  height: 45px;
  text-align: center;
  border: none;
  border-radius: 5px;
}

.hesadersec .navbar-toggler {
  border: none;
}

.hesadersec .navbar-toggler:focus {
  border: none;
  box-shadow: unset;
}

.icondistribution img {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: block;
}

.cryptoinfo {
  background-color: #f7f7f7;
  padding: 15px 10px;
  border-left: 3px solid #f18c20;
  border-radius: 5px;
  width: 100%;
  max-width: 70%;
}

.getstartim img {
  width: 100%;
  max-width: 100px;
}

.feature-sec {
  height: auto;

  width: 100%;
  background-color: var(--home-bg);
  padding: 20px 10px;
}

.icofooter {
  color: #f4a012;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.left-icon {
  left: 69%;
  margin-left: -1px;
  animation: jump 1s linear 0s infinite alternate;
}

@keyframes jump {
  to {
    transform: translateY(-20px);
    /* Jumping position */
  }
}

.ico-home {
  background-color: var(--bbody-color);
}

.ico-home h5 {
  color: var(--color-800);
}

.ico-home h2 {
  color: var(--color-900);
}

.bannercontent span {
  color: var(--color-500);
}

.ico-home h6 {
  color: var(--color-500);
}

.ico-home h1 {
  color: var(--color-900);
}

.ico-home p {
  color: var(--color-600);
}

.header-image-icon {
  position: absolute;
  top: 32%;
  width: 30px !important;
}

.right-icon {
  right: 25%;
  margin-right: -5px;
  margin-top: 5px;
  animation: jump 1s linear 0.5s infinite alternate;
}

.cryptoinfo h5 {
  font-size: 16px;
}

#stars-container {
  height: 100%;

  background-color: var(--card-color);
  overflow: hidden;
  -webkit-transform: translate(-50, -50%);
  -ms-transform: translate(-50, -50%);
  -o-transform: translate(-50, -50%);
  transform: translate(-50, -50%);
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 0 589px 1602px #f18c20, 1578px 1314px #f18c20,
    1457px 1400px #f18c20, 874px 624px #f18c20, 555px 1451px #f18c20,
    555px 860px #f18c20, 1020px 894px #f18c20, 1236px 685px #f18c20,
    716px 216px #f18c20, 317px 904px #f18c20, 678px 982px #f18c20,
    123px 1652px #f18c20, 968px 734px #f18c20, 278px 1075px #f18c20,
    1855px 1795px #f18c20, 1593px 662px #f18c20, 1509px 1117px #f18c20,
    1224px 30px #f18c20, 1545px 347px #f18c20, 910px 157px #f18c20,
    703px 1441px #f18c20, 1660px 1429px #f18c20, 1667px 981px #f18c20,
    590px 1715px #f18c20, 1983px 1046px #f18c20, 44px 226px #f18c20,
    1105px 1069px #f18c20, 1091px 1255px #f18c20, 569px 898px #f18c20,
    683px 303px #f18c20, 799px 739px #f18c20, 1310px 151px #f18c20,
    679px 1982px #f18c20, 145px 1416px #f18c20, 1981px 1279px #f18c20,
    1723px 1913px #f18c20, 268px 1508px #f18c20, 615px 361px #f18c20,
    1437px 214px #f18c20, 74px 1213px #f18c20, 657px 607px #f18c20,
    341px 948px #f18c20, 1825px 1878px #f18c20, 1196px 1644px #f18c20,
    1970px 513px #f18c20, 1447px 1330px #f18c20, 327px 989px #f18c20,
    1785px 1162px #f18c20, 647px 473px #f18c20, 1626px 1486px #f18c20,
    629px 1020px #f18c20, 1632px 674px #f18c20, 704px 1109px #f18c20,
    889px 1296px #f18c20, 389px 753px #f18c20, 512px 192px #f18c20,
    62px 1027px #f18c20, 940px 1876px #f18c20, 1371px 1924px #f18c20,
    1219px 1117px #f18c20, 365px 194px #f18c20, 1890px 1874px #f18c20,
    384px 775px #f18c20, 222px 307px #f18c20, 1314px 1019px #f18c20,
    1004px 1355px #f18c20, 1191px 737px #f18c20, 1579px 444px #f18c20,
    1538px 1043px #f18c20, 1978px 768px #f18c20, 1852px 1391px #f18c20,
    1942px 1318px #f18c20, 145px 1427px #f18c20, 583px 695px #f18c20,
    1071px 590px #f18c20, 858px 893px #f18c20, 189px 1940px #f18c20,
    1290px 286px #f18c20, 1914px 26px #f18c20, 62px 253px #f18c20,
    1278px 1091px #f18c20, 51px 1021px #f18c20, 82px 278px #f18c20,
    940px 1619px #f18c20, 1183px 511px #f18c20, 482px 843px #f18c20,
    1182px 1579px #f18c20, 608px 254px #f18c20, 1022px 1992px #f18c20,
    1658px 92px #f18c20, 539px 1996px #f18c20, 1579px 1630px #f18c20,
    1145px 1410px #f18c20, 1888px 1213px #f18c20, 1587px 1435px #f18c20,
    1586px 1486px #f18c20, 527px 590px #f18c20, 1839px 852px #f18c20,
    323px 1270px #f18c20, 1956px 1799px #f18c20, 1737px 1005px #f18c20,
    1544px 1286px #f18c20, 831px 970px #f18c20, 1700px 868px #f18c20,
    1783px 673px #f18c20, 697px 1431px #f18c20, 427px 1960px #f18c20,
    819px 173px #f18c20, 505px 1920px #f18c20, 679px 299px #f18c20,
    44px 156px #f18c20, 1572px 1183px #f18c20, 1931px 1282px #f18c20,
    711px 343px #f18c20, 1876px 1616px #f18c20, 828px 251px #f18c20,
    869px 1741px #f18c20, 1340px 33px #f18c20, 1051px 115px #f18c20,
    25px 213px #f18c20, 929px 1321px #f18c20, 1286px 1515px #f18c20,
    1762px 1029px #f18c20, 1176px 1547px #f18c20, 1700px 581px #f18c20,
    645px 1527px #f18c20, 1417px 1577px #f18c20, 1735px 1673px #f18c20,
    1905px 1553px #f18c20, 207px 1288px #f18c20, 311px 616px #f18c20,
    470px 1777px #f18c20, 789px 559px #f18c20, 914px 703px #f18c20,
    817px 1513px #f18c20, 166px 937px #f18c20, 562px 478px #f18c20,
    1115px 19px #f18c20, 955px 547px #f18c20, 1924px 1667px #f18c20,
    8px 418px #f18c20, 763px 1930px #f18c20, 1529px 1019px #f18c20,
    543px 24px #f18c20, 1185px 1182px #f18c20, 417px 1791px #f18c20,
    1302px 1227px #f18c20, 1019px 271px #f18c20, 221px 73px #f18c20,
    1718px 925px #f18c20, 329px 311px #f18c20, 414px 946px #f18c20,
    958px 19px #f18c20, 1022px 1471px #f18c20, 1854px 303px #f18c20,
    711px 442px #f18c20, 15px 1001px #f18c20, 1244px 1669px #f18c20,
    1302px 1475px #f18c20, 125px 829px #f18c20, 1209px 413px #f18c20,
    1184px 1102px #f18c20, 727px 1415px #f18c20, 1288px 1212px #f18c20,
    1803px 1277px #f18c20, 968px 1929px #f18c20, 375px 380px #f18c20,
    739px 1369px #f18c20, 584px 1578px #f18c20, 125px 62px #f18c20,
    1184px 122px #f18c20, 546px 737px #f18c20, 996px 25px #f18c20,
    1591px 913px #f18c20, 796px 828px #f18c20, 944px 975px #f18c20,
    1158px 1957px #f18c20, 1989px 1775px #f18c20, 935px 939px #f18c20,
    546px 1690px #f18c20, 203px 484px #f18c20, 252px 1437px #f18c20,
    1215px 662px #f18c20, 1969px 132px #f18c20, 259px 553px #f18c20,
    199px 206px #f18c20, 533px 1716px #f18c20, 698px 1904px #f18c20,
    876px 1006px #f18c20, 336px 63px #f18c20, 701px 1695px #f18c20,
    1511px 1711px #f18c20, 445px 165px #f18c20, 427px 229px #f18c20,
    1972px 1074px #f18c20, 959px 1073px #f18c20, 255px 480px #f18c20,
    458px 1704px #f18c20, 1378px 767px #f18c20, 1476px 166px #f18c20,
    885px 1195px #f18c20, 1500px 582px #f18c20, 1754px 597px #f18c20,
    1930px 1670px #f18c20, 582px 1881px #f18c20, 614px 980px #f18c20,
    1429px 143px #f18c20, 881px 1647px #f18c20, 1182px 1580px #f18c20,
    416px 1554px #f18c20, 1525px 620px #f18c20, 318px 1259px #f18c20,
    490px 1074px #f18c20, 307px 489px #f18c20, 1824px 1195px #f18c20,
    454px 784px #f18c20, 861px 1900px #f18c20, 466px 616px #f18c20,
    866px 1417px #f18c20, 58px 1515px #f18c20, 1419px 1242px #f18c20,
    901px 837px #f18c20, 1089px 136px #f18c20, 721px 400px #f18c20,
    1995px 112px #f18c20, 1572px 629px #f18c20, 1842px 1602px #f18c20,
    501px 1805px #f18c20, 1965px 530px #f18c20, 893px 1798px #f18c20,
    1382px 1111px #f18c20, 1215px 72px #f18c20, 735px 620px #f18c20,
    989px 769px #f18c20, 205px 385px #f18c20, 1312px 1809px #f18c20,
    957px 46px #f18c20, 1144px 1765px #f18c20, 810px 589px #f18c20,
    771px 1797px #f18c20, 148px 1550px #f18c20, 465px 452px #f18c20,
    771px 414px #f18c20, 1448px 99px #f18c20, 897px 1781px #f18c20,
    104px 426px #f18c20, 1052px 140px #f18c20, 1025px 916px #f18c20,
    194px 1195px #f18c20, 969px 375px #f18c20, 104px 1002px #f18c20,
    200px 1380px #f18c20, 976px 800px #f18c20, 179px 960px #f18c20,
    133px 1313px #f18c20, 719px 954px #f18c20, 1318px 1157px #f18c20,
    928px 1952px #f18c20, 544px 550px #f18c20, 705px 1959px #f18c20,
    616px 371px #f18c20, 1466px 866px #f18c20, 475px 1428px #f18c20,
    1739px 197px #f18c20, 1535px 827px #f18c20, 1178px 452px #f18c20,
    1784px 479px #f18c20, 1290px 132px #f18c20, 656px 243px #f18c20,
    458px 864px #f18c20, 1043px 86px #f18c20, 1737px 1582px #f18c20,
    1717px 91px #f18c20, 542px 248px #f18c20, 133px 871px #f18c20,
    102px 1913px #f18c20, 58px 980px #f18c20, 581px 1403px #f18c20,
    919px 1811px #f18c20, 1787px 928px #f18c20, 677px 999px #f18c20,
    807px 1964px #f18c20, 235px 1082px #f18c20, 810px 1489px #f18c20,
    1882px 1151px #f18c20, 131px 677px #f18c20, 64px 987px #f18c20,
    1124px 688px #f18c20, 163px 110px #f18c20, 1561px 1539px #f18c20,
    119px 1863px #f18c20, 576px 174px #f18c20, 1889px 1746px #f18c20,
    535px 449px #f18c20, 1474px 819px #f18c20, 1901px 1316px #f18c20,
    549px 195px #f18c20, 179px 272px #f18c20, 521px 1513px #f18c20,
    1320px 1812px #f18c20, 1853px 142px #f18c20, 146px 932px #f18c20,
    1722px 1531px #f18c20, 802px 1976px #f18c20, 1878px 1081px #f18c20,
    1753px 325px #f18c20, 40px 416px #f18c20, 445px 399px #f18c20,
    1880px 1378px #f18c20, 1569px 1687px #f18c20, 1084px 1753px #f18c20,
    774px 958px #f18c20, 1355px 1973px #f18c20, 686px 1728px #f18c20,
    563px 1416px #f18c20, 1195px 754px #f18c20, 1630px 651px #f18c20,
    1122px 329px #f18c20, 459px 41px #f18c20, 1905px 300px #f18c20,
    1905px 667px #f18c20, 898px 110px #f18c20, 639px 133px #f18c20,
    468px 568px #f18c20, 679px 419px #f18c20, 694px 1626px #f18c20,
    1209px 566px #f18c20, 349px 1703px #f18c20, 176px 1810px #f18c20,
    1613px 1753px #f18c20, 1989px 246px #f18c20, 1928px 1613px #f18c20,
    323px 530px #f18c20, 1494px 1138px #f18c20, 55px 1644px #f18c20,
    375px 687px #f18c20, 1421px 1500px #f18c20, 328px 1682px #f18c20,
    837px 250px #f18c20, 997px 434px #f18c20, 1571px 1804px #f18c20,
    524px 1097px #f18c20, 703px 288px #f18c20, 284px 847px #f18c20,
    841px 921px #f18c20, 514px 1012px #f18c20, 1933px 1383px #f18c20,
    1003px 1878px #f18c20, 734px 739px #f18c20, 845px 100px #f18c20,
    100px 443px #f18c20, 985px 805px #f18c20, 1642px 248px #f18c20,
    1160px 52px #f18c20, 1117px 123px #f18c20, 1903px 1937px #f18c20,
    1785px 1845px #f18c20, 1414px 1772px #f18c20, 533px 1305px #f18c20,
    106px 681px #f18c20, 1415px 1113px #f18c20, 835px 1382px #f18c20,
    838px 704px #f18c20, 396px 1618px #f18c20, 1023px 105px #f18c20,
    770px 576px #f18c20, 111px 433px #f18c20, 21px 711px #f18c20,
    1326px 179px #f18c20, 387px 351px #f18c20, 1136px 318px #f18c20,
    1154px 271px #f18c20, 1760px 1645px #f18c20, 1626px 1119px #f18c20,
    1299px 1125px #f18c20, 1797px 737px #f18c20, 1075px 406px #f18c20,
    1545px 247px #f18c20, 1097px 119px #f18c20, 1443px 299px #f18c20,
    1698px 72px #f18c20, 835px 1226px #f18c20, 105px 335px #f18c20,
    1898px 1899px #f18c20, 993px 260px #f18c20, 1573px 1837px #f18c20,
    1659px 1345px #f18c20, 1558px 1604px #f18c20, 1692px 1396px #f18c20,
    1566px 604px #f18c20, 328px 892px #f18c20, 516px 143px #f18c20,
    219px 1121px #f18c20, 973px 1647px #f18c20, 1741px 552px #f18c20,
    900px 1796px #f18c20, 1579px 523px #f18c20, 443px 1042px #f18c20,
    616px 799px #f18c20, 587px 1573px #f18c20, 1426px 321px #f18c20,
    1980px 1033px #f18c20, 762px 6px #f18c20, 1699px 825px #f18c20,
    1405px 1340px #f18c20, 712px 902px #f18c20, 153px 1784px #f18c20,
    1951px 1938px #f18c20, 1969px 911px #f18c20, 1291px 1357px #f18c20,
    973px 1669px #f18c20, 993px 1225px #f18c20, 382px 1254px #f18c20,
    813px 1845px #f18c20, 602px 1831px #f18c20, 1762px 177px #f18c20,
    939px 1014px #f18c20, 134px 22px #f18c20, 168px 1403px #f18c20,
    981px 47px #f18c20, 467px 1940px #f18c20, 1572px 1721px #f18c20,
    1509px 130px #f18c20, 1081px 968px #f18c20, 1127px 819px #f18c20,
    19px 1670px #f18c20, 289px 174px #f18c20, 868px 53px #f18c20,
    673px 1501px #f18c20, 1737px 857px #f18c20, 1037px 1425px #f18c20,
    1516px 964px #f18c20, 613px 1123px #f18c20, 729px 340px #f18c20,
    1193px 1365px #f18c20, 578px 191px #f18c20, 976px 1198px #f18c20,
    1264px 1987px #f18c20, 326px 398px #f18c20, 1371px 962px #f18c20,
    1570px 1180px #f18c20, 1985px 395px #f18c20, 1337px 841px #f18c20,
    1678px 58px #f18c20, 33px 134px #f18c20, 1761px 936px #f18c20,
    353px 154px #f18c20, 611px 468px #f18c20, 1857px 397px #f18c20,
    1402px 1679px #f18c20, 1536px 658px #f18c20, 1944px 437px #f18c20,
    1867px 705px #f18c20, 1074px 1116px #f18c20, 1742px 934px #f18c20,
    567px 65px #f18c20, 1864px 1360px #f18c20, 644px 20px #f18c20,
    127px 924px #f18c20, 1018px 138px #f18c20, 1090px 1690px #f18c20,
    76px 578px #f18c20, 1550px 74px #f18c20, 91px 1px #f18c20,
    521px 96px #f18c20, 838px 471px #f18c20, 616px 1539px #f18c20,
    1421px 155px #f18c20, 1907px 118px #f18c20, 355px 1759px #f18c20,
    406px 84px #f18c20, 1211px 1077px #f18c20, 1696px 1488px #f18c20,
    782px 1807px #f18c20, 1836px 1915px #f18c20, 1349px 944px #f18c20,
    1111px 852px #f18c20, 1762px 1659px #f18c20, 475px 691px #f18c20,
    831px 1389px #f18c20, 1907px 1586px #f18c20, 730px 1264px #f18c20,
    554px 1336px #f18c20, 1548px 369px #f18c20, 95px 1661px #f18c20,
    1898px 253px #f18c20, 627px 182px #f18c20, 636px 882px #f18c20,
    1998px 219px #f18c20, 1267px 346px #f18c20, 1222px 1611px #f18c20,
    66px 357px #f18c20, 984px 1384px #f18c20, 441px 717px #f18c20,
    1129px 619px #f18c20, 754px 142px #f18c20, 811px 1054px #f18c20,
    988px 454px #f18c20, 1615px 1319px #f18c20, 30px 1928px #f18c20,
    1332px 1996px #f18c20, 254px 238px #f18c20, 1040px 613px #f18c20,
    881px 880px #f18c20, 280px 618px #f18c20, 1075px 462px #f18c20,
    607px 1936px #f18c20, 1582px 1369px #f18c20, 900px 1968px #f18c20,
    1991px 430px #f18c20, 888px 543px #f18c20, 506px 881px #f18c20,
    486px 217px #f18c20, 262px 1606px #f18c20, 1907px 1557px #f18c20,
    1499px 771px #f18c20, 307px 1575px #f18c20, 1315px 1991px #f18c20,
    114px 378px #f18c20, 349px 1535px #f18c20, 101px 1287px #f18c20,
    377px 343px #f18c20, 663px 697px #f18c20, 1647px 1465px #f18c20,
    1486px 1461px #f18c20, 496px 512px #f18c20, 480px 107px #f18c20,
    1651px 154px #f18c20, 611px 591px #f18c20, 767px 859px #f18c20,
    894px 1769px #f18c20, 778px 1143px #f18c20, 1636px 484px #f18c20,
    612px 1299px #f18c20, 307px 1008px #f18c20, 1794px 1782px #f18c20,
    683px 1228px #f18c20, 1644px 1033px #f18c20, 558px 56px #f18c20,
    1671px 312px #f18c20, 990px 1461px #f18c20, 1969px 18px #f18c20,
    1855px 1993px #f18c20, 1934px 1328px #f18c20, 1309px 1659px #f18c20,
    565px 132px #f18c20, 740px 1503px #f18c20, 258px 1149px #f18c20,
    1806px 333px #f18c20, 740px 811px #f18c20, 1006px 1584px #f18c20,
    1987px 1446px #f18c20, 995px 645px #f18c20, 1957px 370px #f18c20,
    499px 562px #f18c20, 1822px 832px #f18c20, 469px 1916px #f18c20,
    2000px 262px #f18c20, 778px 1959px #f18c20, 1596px 1924px #f18c20,
    670px 170px #f18c20, 616px 126px #f18c20, 1391px 899px #f18c20,
    1058px 774px #f18c20, 1018px 640px #f18c20, 1967px 118px #f18c20,
    582px 633px #f18c20, 97px 1033px #f18c20, 1442px 174px #f18c20,
    970px 1242px #f18c20, 1128px 462px #f18c20, 1928px 1607px #f18c20,
    560px 709px #f18c20, 1901px 406px #f18c20, 1206px 1847px #f18c20,
    132px 1216px #f18c20, 211px 1260px #f18c20, 1881px 1598px #f18c20,
    1988px 964px #f18c20, 261px 253px #f18c20, 242px 741px #f18c20,
    662px 13px #f18c20, 1614px 781px #f18c20, 1337px 329px #f18c20,
    1141px 1291px #f18c20, 643px 1574px #f18c20, 749px 1610px #f18c20,
    1754px 40px #f18c20, 1264px 1600px #f18c20, 835px 373px #f18c20,
    1774px 1329px #f18c20, 1379px 1775px #f18c20, 1540px 1326px #f18c20,
    479px 597px #f18c20, 1254px 235px #f18c20, 177px 663px #f18c20,
    1919px 1792px #f18c20, 1664px 1749px #f18c20, 1234px 1889px #f18c20,
    23px 1723px #f18c20, 970px 1936px #f18c20, 1593px 215px #f18c20,
    1176px 1729px #f18c20, 891px 1125px #f18c20, 985px 1961px #f18c20,
    807px 800px #f18c20, 1894px 101px #f18c20, 735px 948px #f18c20,
    171px 732px #f18c20, 1658px 1720px #f18c20, 341px 34px #f18c20,
    1612px 1052px #f18c20, 1080px 611px #f18c20, 1026px 1812px #f18c20,
    1847px 911px #f18c20, 140px 245px #f18c20, 1540px 426px #f18c20,
    1083px 700px #f18c20, 1092px 1171px #f18c20, 893px 1407px #f18c20,
    1351px 4px #f18c20, 65px 942px #f18c20, 249px 151px #f18c20,
    1744px 1238px #f18c20, 881px 1760px #f18c20, 916px 1095px #f18c20,
    579px 331px #f18c20, 1624px 1695px #f18c20, 1230px 1958px #f18c20,
    67px 229px #f18c20, 1857px 1664px #f18c20, 1860px 1601px #f18c20,
    346px 542px #f18c20, 892px 1572px #f18c20, 417px 1955px #f18c20,
    1322px 834px #f18c20, 21px 535px #f18c20, 136px 947px #f18c20,
    207px 911px #f18c20, 342px 527px #f18c20, 1240px 1281px #f18c20,
    407px 1808px #f18c20, 1437px 976px #f18c20, 660px 463px #f18c20,
    34px 579px #f18c20, 576px 582px #f18c20, 320px 525px #f18c20,
    96px 1094px #f18c20, 1695px 917px #f18c20, 811px 148px #f18c20,
    722px 400px #f18c20, 641px 1565px #f18c20, 549px 630px #f18c20,
    973px 170px #f18c20, 237px 1553px #f18c20, 891px 402px #f18c20,
    1569px 26px #f18c20, 1682px 1453px #f18c20, 1696px 445px #f18c20,
    845px 659px #f18c20, 488px 192px #f18c20, 1165px 1351px #f18c20,
    1844px 1828px #f18c20, 29px 302px #f18c20, 1280px 1807px #f18c20,
    595px 779px #f18c20, 1330px 861px #f18c20, 427px 1361px #f18c20,
    1416px 1806px #f18c20, 1155px 1168px #f18c20, 1961px 1083px #f18c20,
    1442px 1549px #f18c20, 1701px 458px #f18c20, 1147px 522px #f18c20,
    205px 141px #f18c20, 1893px 467px #f18c20, 1679px 174px #f18c20,
    987px 471px #f18c20, 1398px 1740px #f18c20, 401px 571px #f18c20,
    1267px 230px #f18c20, 3px 771px #f18c20, 1663px 1756px #f18c20,
    242px 1814px #f18c20, 1476px 1730px #f18c20, 835px 989px #f18c20,
    1468px 1472px #f18c20, 1027px 595px #f18c20, 1297px 1682px #f18c20,
    1723px 1259px #f18c20, 1040px 276px #f18c20, 896px 79px #f18c20,
    1155px 1695px #f18c20, 831px 244px #f18c20, 139px 638px #f18c20,
    1195px 1518px #f18c20, 4px 1105px #f18c20, 616px 6px #f18c20,
    582px 1230px #f18c20, 1296px 603px #f18c20;
  animation: animateStars 28.5s ease-in-out infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#stars::after {
  content: " ";
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 0 589px 1602px #f18c20, 1578px 1314px #f18c20,
    1457px 1400px #f18c20, 874px 624px #f18c20, 555px 1451px #f18c20,
    555px 860px #f18c20, 1020px 894px #f18c20, 1236px 685px #f18c20,
    716px 216px #f18c20, 317px 904px #f18c20, 678px 982px #f18c20,
    123px 1652px #f18c20, 968px 734px #f18c20, 278px 1075px #f18c20,
    1855px 1795px #f18c20, 1593px 662px #f18c20, 1509px 1117px #f18c20,
    1224px 30px #f18c20, 1545px 347px #f18c20, 910px 157px #f18c20,
    703px 1441px #f18c20, 1660px 1429px #f18c20, 1667px 981px #f18c20,
    590px 1715px #f18c20, 1983px 1046px #f18c20, 44px 226px #f18c20,
    1105px 1069px #f18c20, 1091px 1255px #f18c20, 569px 898px #f18c20,
    683px 303px #f18c20, 799px 739px #f18c20, 1310px 151px #f18c20,
    679px 1982px #f18c20, 145px 1416px #f18c20, 1981px 1279px #f18c20,
    1723px 1913px #f18c20, 268px 1508px #f18c20, 615px 361px #f18c20,
    1437px 214px #f18c20, 74px 1213px #f18c20, 657px 607px #f18c20,
    341px 948px #f18c20, 1825px 1878px #f18c20, 1196px 1644px #f18c20,
    1970px 513px #f18c20, 1447px 1330px #f18c20, 327px 989px #f18c20,
    1785px 1162px #f18c20, 647px 473px #f18c20, 1626px 1486px #f18c20,
    629px 1020px #f18c20, 1632px 674px #f18c20, 704px 1109px #f18c20,
    889px 1296px #f18c20, 389px 753px #f18c20, 512px 192px #f18c20,
    62px 1027px #f18c20, 940px 1876px #f18c20, 1371px 1924px #f18c20,
    1219px 1117px #f18c20, 365px 194px #f18c20, 1890px 1874px #f18c20,
    384px 775px #f18c20, 222px 307px #f18c20, 1314px 1019px #f18c20,
    1004px 1355px #f18c20, 1191px 737px #f18c20, 1579px 444px #f18c20,
    1538px 1043px #f18c20, 1978px 768px #f18c20, 1852px 1391px #f18c20,
    1942px 1318px #f18c20, 145px 1427px #f18c20, 583px 695px #f18c20,
    1071px 590px #f18c20, 858px 893px #f18c20, 189px 1940px #f18c20,
    1290px 286px #f18c20, 1914px 26px #f18c20, 62px 253px #f18c20,
    1278px 1091px #f18c20, 51px 1021px #f18c20, 82px 278px #f18c20,
    940px 1619px #f18c20, 1183px 511px #f18c20, 482px 843px #f18c20,
    1182px 1579px #f18c20, 608px 254px #f18c20, 1022px 1992px #f18c20,
    1658px 92px #f18c20, 539px 1996px #f18c20, 1579px 1630px #f18c20,
    1145px 1410px #f18c20, 1888px 1213px #f18c20, 1587px 1435px #f18c20,
    1586px 1486px #f18c20, 527px 590px #f18c20, 1839px 852px #f18c20,
    323px 1270px #f18c20, 1956px 1799px #f18c20, 1737px 1005px #f18c20,
    1544px 1286px #f18c20, 831px 970px #f18c20, 1700px 868px #f18c20,
    1783px 673px #f18c20, 697px 1431px #f18c20, 427px 1960px #f18c20,
    819px 173px #f18c20, 505px 1920px #f18c20, 679px 299px #f18c20,
    44px 156px #f18c20, 1572px 1183px #f18c20, 1931px 1282px #f18c20,
    711px 343px #f18c20, 1876px 1616px #f18c20, 828px 251px #f18c20,
    869px 1741px #f18c20, 1340px 33px #f18c20, 1051px 115px #f18c20,
    25px 213px #f18c20, 929px 1321px #f18c20, 1286px 1515px #f18c20,
    1762px 1029px #f18c20, 1176px 1547px #f18c20, 1700px 581px #f18c20,
    645px 1527px #f18c20, 1417px 1577px #f18c20, 1735px 1673px #f18c20,
    1905px 1553px #f18c20, 207px 1288px #f18c20, 311px 616px #f18c20,
    470px 1777px #f18c20, 789px 559px #f18c20, 914px 703px #f18c20,
    817px 1513px #f18c20, 166px 937px #f18c20, 562px 478px #f18c20,
    1115px 19px #f18c20, 955px 547px #f18c20, 1924px 1667px #f18c20,
    8px 418px #f18c20, 763px 1930px #f18c20, 1529px 1019px #f18c20,
    543px 24px #f18c20, 1185px 1182px #f18c20, 417px 1791px #f18c20,
    1302px 1227px #f18c20, 1019px 271px #f18c20, 221px 73px #f18c20,
    1718px 925px #f18c20, 329px 311px #f18c20, 414px 946px #f18c20,
    958px 19px #f18c20, 1022px 1471px #f18c20, 1854px 303px #f18c20,
    711px 442px #f18c20, 15px 1001px #f18c20, 1244px 1669px #f18c20,
    1302px 1475px #f18c20, 125px 829px #f18c20, 1209px 413px #f18c20,
    1184px 1102px #f18c20, 727px 1415px #f18c20, 1288px 1212px #f18c20,
    1803px 1277px #f18c20, 968px 1929px #f18c20, 375px 380px #f18c20,
    739px 1369px #f18c20, 584px 1578px #f18c20, 125px 62px #f18c20,
    1184px 122px #f18c20, 546px 737px #f18c20, 996px 25px #f18c20,
    1591px 913px #f18c20, 796px 828px #f18c20, 944px 975px #f18c20,
    1158px 1957px #f18c20, 1989px 1775px #f18c20, 935px 939px #f18c20,
    546px 1690px #f18c20, 203px 484px #f18c20, 252px 1437px #f18c20,
    1215px 662px #f18c20, 1969px 132px #f18c20, 259px 553px #f18c20,
    199px 206px #f18c20, 533px 1716px #f18c20, 698px 1904px #f18c20,
    876px 1006px #f18c20, 336px 63px #f18c20, 701px 1695px #f18c20,
    1511px 1711px #f18c20, 445px 165px #f18c20, 427px 229px #f18c20,
    1972px 1074px #f18c20, 959px 1073px #f18c20, 255px 480px #f18c20,
    458px 1704px #f18c20, 1378px 767px #f18c20, 1476px 166px #f18c20,
    885px 1195px #f18c20, 1500px 582px #f18c20, 1754px 597px #f18c20,
    1930px 1670px #f18c20, 582px 1881px #f18c20, 614px 980px #f18c20,
    1429px 143px #f18c20, 881px 1647px #f18c20, 1182px 1580px #f18c20,
    416px 1554px #f18c20, 1525px 620px #f18c20, 318px 1259px #f18c20,
    490px 1074px #f18c20, 307px 489px #f18c20, 1824px 1195px #f18c20,
    454px 784px #f18c20, 861px 1900px #f18c20, 466px 616px #f18c20,
    866px 1417px #f18c20, 58px 1515px #f18c20, 1419px 1242px #f18c20,
    901px 837px #f18c20, 1089px 136px #f18c20, 721px 400px #f18c20,
    1995px 112px #f18c20, 1572px 629px #f18c20, 1842px 1602px #f18c20,
    501px 1805px #f18c20, 1965px 530px #f18c20, 893px 1798px #f18c20,
    1382px 1111px #f18c20, 1215px 72px #f18c20, 735px 620px #f18c20,
    989px 769px #f18c20, 205px 385px #f18c20, 1312px 1809px #f18c20,
    957px 46px #f18c20, 1144px 1765px #f18c20, 810px 589px #f18c20,
    771px 1797px #f18c20, 148px 1550px #f18c20, 465px 452px #f18c20,
    771px 414px #f18c20, 1448px 99px #f18c20, 897px 1781px #f18c20,
    104px 426px #f18c20, 1052px 140px #f18c20, 1025px 916px #f18c20,
    194px 1195px #f18c20, 969px 375px #f18c20, 104px 1002px #f18c20,
    200px 1380px #f18c20, 976px 800px #f18c20, 179px 960px #f18c20,
    133px 1313px #f18c20, 719px 954px #f18c20, 1318px 1157px #f18c20,
    928px 1952px #f18c20, 544px 550px #f18c20, 705px 1959px #f18c20,
    616px 371px #f18c20, 1466px 866px #f18c20, 475px 1428px #f18c20,
    1739px 197px #f18c20, 1535px 827px #f18c20, 1178px 452px #f18c20,
    1784px 479px #f18c20, 1290px 132px #f18c20, 656px 243px #f18c20,
    458px 864px #f18c20, 1043px 86px #f18c20, 1737px 1582px #f18c20,
    1717px 91px #f18c20, 542px 248px #f18c20, 133px 871px #f18c20,
    102px 1913px #f18c20, 58px 980px #f18c20, 581px 1403px #f18c20,
    919px 1811px #f18c20, 1787px 928px #f18c20, 677px 999px #f18c20,
    807px 1964px #f18c20, 235px 1082px #f18c20, 810px 1489px #f18c20,
    1882px 1151px #f18c20, 131px 677px #f18c20, 64px 987px #f18c20,
    1124px 688px #f18c20, 163px 110px #f18c20, 1561px 1539px #f18c20,
    119px 1863px #f18c20, 576px 174px #f18c20, 1889px 1746px #f18c20,
    535px 449px #f18c20, 1474px 819px #f18c20, 1901px 1316px #f18c20,
    549px 195px #f18c20, 179px 272px #f18c20, 521px 1513px #f18c20,
    1320px 1812px #f18c20, 1853px 142px #f18c20, 146px 932px #f18c20,
    1722px 1531px #f18c20, 802px 1976px #f18c20, 1878px 1081px #f18c20,
    1753px 325px #f18c20, 40px 416px #f18c20, 445px 399px #f18c20,
    1880px 1378px #f18c20, 1569px 1687px #f18c20, 1084px 1753px #f18c20,
    774px 958px #f18c20, 1355px 1973px #f18c20, 686px 1728px #f18c20,
    563px 1416px #f18c20, 1195px 754px #f18c20, 1630px 651px #f18c20,
    1122px 329px #f18c20, 459px 41px #f18c20, 1905px 300px #f18c20,
    1905px 667px #f18c20, 898px 110px #f18c20, 639px 133px #f18c20,
    468px 568px #f18c20, 679px 419px #f18c20, 694px 1626px #f18c20,
    1209px 566px #f18c20, 349px 1703px #f18c20, 176px 1810px #f18c20,
    1613px 1753px #f18c20, 1989px 246px #f18c20, 1928px 1613px #f18c20,
    323px 530px #f18c20, 1494px 1138px #f18c20, 55px 1644px #f18c20,
    375px 687px #f18c20, 1421px 1500px #f18c20, 328px 1682px #f18c20,
    837px 250px #f18c20, 997px 434px #f18c20, 1571px 1804px #f18c20,
    524px 1097px #f18c20, 703px 288px #f18c20, 284px 847px #f18c20,
    841px 921px #f18c20, 514px 1012px #f18c20, 1933px 1383px #f18c20,
    1003px 1878px #f18c20, 734px 739px #f18c20, 845px 100px #f18c20,
    100px 443px #f18c20, 985px 805px #f18c20, 1642px 248px #f18c20,
    1160px 52px #f18c20, 1117px 123px #f18c20, 1903px 1937px #f18c20,
    1785px 1845px #f18c20, 1414px 1772px #f18c20, 533px 1305px #f18c20,
    106px 681px #f18c20, 1415px 1113px #f18c20, 835px 1382px #f18c20,
    838px 704px #f18c20, 396px 1618px #f18c20, 1023px 105px #f18c20,
    770px 576px #f18c20, 111px 433px #f18c20, 21px 711px #f18c20,
    1326px 179px #f18c20, 387px 351px #f18c20, 1136px 318px #f18c20,
    1154px 271px #f18c20, 1760px 1645px #f18c20, 1626px 1119px #f18c20,
    1299px 1125px #f18c20, 1797px 737px #f18c20, 1075px 406px #f18c20,
    1545px 247px #f18c20, 1097px 119px #f18c20, 1443px 299px #f18c20,
    1698px 72px #f18c20, 835px 1226px #f18c20, 105px 335px #f18c20,
    1898px 1899px #f18c20, 993px 260px #f18c20, 1573px 1837px #f18c20,
    1659px 1345px #f18c20, 1558px 1604px #f18c20, 1692px 1396px #f18c20,
    1566px 604px #f18c20, 328px 892px #f18c20, 516px 143px #f18c20,
    219px 1121px #f18c20, 973px 1647px #f18c20, 1741px 552px #f18c20,
    900px 1796px #f18c20, 1579px 523px #f18c20, 443px 1042px #f18c20,
    616px 799px #f18c20, 587px 1573px #f18c20, 1426px 321px #f18c20,
    1980px 1033px #f18c20, 762px 6px #f18c20, 1699px 825px #f18c20,
    1405px 1340px #f18c20, 712px 902px #f18c20, 153px 1784px #f18c20,
    1951px 1938px #f18c20, 1969px 911px #f18c20, 1291px 1357px #f18c20,
    973px 1669px #f18c20, 993px 1225px #f18c20, 382px 1254px #f18c20,
    813px 1845px #f18c20, 602px 1831px #f18c20, 1762px 177px #f18c20,
    939px 1014px #f18c20, 134px 22px #f18c20, 168px 1403px #f18c20,
    981px 47px #f18c20, 467px 1940px #f18c20, 1572px 1721px #f18c20,
    1509px 130px #f18c20, 1081px 968px #f18c20, 1127px 819px #f18c20,
    19px 1670px #f18c20, 289px 174px #f18c20, 868px 53px #f18c20,
    673px 1501px #f18c20, 1737px 857px #f18c20, 1037px 1425px #f18c20,
    1516px 964px #f18c20, 613px 1123px #f18c20, 729px 340px #f18c20,
    1193px 1365px #f18c20, 578px 191px #f18c20, 976px 1198px #f18c20,
    1264px 1987px #f18c20, 326px 398px #f18c20, 1371px 962px #f18c20,
    1570px 1180px #f18c20, 1985px 395px #f18c20, 1337px 841px #f18c20,
    1678px 58px #f18c20, 33px 134px #f18c20, 1761px 936px #f18c20,
    353px 154px #f18c20, 611px 468px #f18c20, 1857px 397px #f18c20,
    1402px 1679px #f18c20, 1536px 658px #f18c20, 1944px 437px #f18c20,
    1867px 705px #f18c20, 1074px 1116px #f18c20, 1742px 934px #f18c20,
    567px 65px #f18c20, 1864px 1360px #f18c20, 644px 20px #f18c20,
    127px 924px #f18c20, 1018px 138px #f18c20, 1090px 1690px #f18c20,
    76px 578px #f18c20, 1550px 74px #f18c20, 91px 1px #f18c20,
    521px 96px #f18c20, 838px 471px #f18c20, 616px 1539px #f18c20,
    1421px 155px #f18c20, 1907px 118px #f18c20, 355px 1759px #f18c20,
    406px 84px #f18c20, 1211px 1077px #f18c20, 1696px 1488px #f18c20,
    782px 1807px #f18c20, 1836px 1915px #f18c20, 1349px 944px #f18c20,
    1111px 852px #f18c20, 1762px 1659px #f18c20, 475px 691px #f18c20,
    831px 1389px #f18c20, 1907px 1586px #f18c20, 730px 1264px #f18c20,
    554px 1336px #f18c20, 1548px 369px #f18c20, 95px 1661px #f18c20,
    1898px 253px #f18c20, 627px 182px #f18c20, 636px 882px #f18c20,
    1998px 219px #f18c20, 1267px 346px #f18c20, 1222px 1611px #f18c20,
    66px 357px #f18c20, 984px 1384px #f18c20, 441px 717px #f18c20,
    1129px 619px #f18c20, 754px 142px #f18c20, 811px 1054px #f18c20,
    988px 454px #f18c20, 1615px 1319px #f18c20, 30px 1928px #f18c20,
    1332px 1996px #f18c20, 254px 238px #f18c20, 1040px 613px #f18c20,
    881px 880px #f18c20, 280px 618px #f18c20, 1075px 462px #f18c20,
    607px 1936px #f18c20, 1582px 1369px #f18c20, 900px 1968px #f18c20,
    1991px 430px #f18c20, 888px 543px #f18c20, 506px 881px #f18c20,
    486px 217px #f18c20, 262px 1606px #f18c20, 1907px 1557px #f18c20,
    1499px 771px #f18c20, 307px 1575px #f18c20, 1315px 1991px #f18c20,
    114px 378px #f18c20, 349px 1535px #f18c20, 101px 1287px #f18c20,
    377px 343px #f18c20, 663px 697px #f18c20, 1647px 1465px #f18c20,
    1486px 1461px #f18c20, 496px 512px #f18c20, 480px 107px #f18c20,
    1651px 154px #f18c20, 611px 591px #f18c20, 767px 859px #f18c20,
    894px 1769px #f18c20, 778px 1143px #f18c20, 1636px 484px #f18c20,
    612px 1299px #f18c20, 307px 1008px #f18c20, 1794px 1782px #f18c20,
    683px 1228px #f18c20, 1644px 1033px #f18c20, 558px 56px #f18c20,
    1671px 312px #f18c20, 990px 1461px #f18c20, 1969px 18px #f18c20,
    1855px 1993px #f18c20, 1934px 1328px #f18c20, 1309px 1659px #f18c20,
    565px 132px #f18c20, 740px 1503px #f18c20, 258px 1149px #f18c20,
    1806px 333px #f18c20, 740px 811px #f18c20, 1006px 1584px #f18c20,
    1987px 1446px #f18c20, 995px 645px #f18c20, 1957px 370px #f18c20,
    499px 562px #f18c20, 1822px 832px #f18c20, 469px 1916px #f18c20,
    2000px 262px #f18c20, 778px 1959px #f18c20, 1596px 1924px #f18c20,
    670px 170px #f18c20, 616px 126px #f18c20, 1391px 899px #f18c20,
    1058px 774px #f18c20, 1018px 640px #f18c20, 1967px 118px #f18c20,
    582px 633px #f18c20, 97px 1033px #f18c20, 1442px 174px #f18c20,
    970px 1242px #f18c20, 1128px 462px #f18c20, 1928px 1607px #f18c20,
    560px 709px #f18c20, 1901px 406px #f18c20, 1206px 1847px #f18c20,
    132px 1216px #f18c20, 211px 1260px #f18c20, 1881px 1598px #f18c20,
    1988px 964px #f18c20, 261px 253px #f18c20, 242px 741px #f18c20,
    662px 13px #f18c20, 1614px 781px #f18c20, 1337px 329px #f18c20,
    1141px 1291px #f18c20, 643px 1574px #f18c20, 749px 1610px #f18c20,
    1754px 40px #f18c20, 1264px 1600px #f18c20, 835px 373px #f18c20,
    1774px 1329px #f18c20, 1379px 1775px #f18c20, 1540px 1326px #f18c20,
    479px 597px #f18c20, 1254px 235px #f18c20, 177px 663px #f18c20,
    1919px 1792px #f18c20, 1664px 1749px #f18c20, 1234px 1889px #f18c20,
    23px 1723px #f18c20, 970px 1936px #f18c20, 1593px 215px #f18c20,
    1176px 1729px #f18c20, 891px 1125px #f18c20, 985px 1961px #f18c20,
    807px 800px #f18c20, 1894px 101px #f18c20, 735px 948px #f18c20,
    171px 732px #f18c20, 1658px 1720px #f18c20, 341px 34px #f18c20,
    1612px 1052px #f18c20, 1080px 611px #f18c20, 1026px 1812px #f18c20,
    1847px 911px #f18c20, 140px 245px #f18c20, 1540px 426px #f18c20,
    1083px 700px #f18c20, 1092px 1171px #f18c20, 893px 1407px #f18c20,
    1351px 4px #f18c20, 65px 942px #f18c20, 249px 151px #f18c20,
    1744px 1238px #f18c20, 881px 1760px #f18c20, 916px 1095px #f18c20,
    579px 331px #f18c20, 1624px 1695px #f18c20, 1230px 1958px #f18c20,
    67px 229px #f18c20, 1857px 1664px #f18c20, 1860px 1601px #f18c20,
    346px 542px #f18c20, 892px 1572px #f18c20, 417px 1955px #f18c20,
    1322px 834px #f18c20, 21px 535px #f18c20, 136px 947px #f18c20,
    207px 911px #f18c20, 342px 527px #f18c20, 1240px 1281px #f18c20,
    407px 1808px #f18c20, 1437px 976px #f18c20, 660px 463px #f18c20,
    34px 579px #f18c20, 576px 582px #f18c20, 320px 525px #f18c20,
    96px 1094px #f18c20, 1695px 917px #f18c20, 811px 148px #f18c20,
    722px 400px #f18c20, 641px 1565px #f18c20, 549px 630px #f18c20,
    973px 170px #f18c20, 237px 1553px #f18c20, 891px 402px #f18c20,
    1569px 26px #f18c20, 1682px 1453px #f18c20, 1696px 445px #f18c20,
    845px 659px #f18c20, 488px 192px #f18c20, 1165px 1351px #f18c20,
    1844px 1828px #f18c20, 29px 302px #f18c20, 1280px 1807px #f18c20,
    595px 779px #f18c20, 1330px 861px #f18c20, 427px 1361px #f18c20,
    1416px 1806px #f18c20, 1155px 1168px #f18c20, 1961px 1083px #f18c20,
    1442px 1549px #f18c20, 1701px 458px #f18c20, 1147px 522px #f18c20,
    205px 141px #f18c20, 1893px 467px #f18c20, 1679px 174px #f18c20,
    987px 471px #f18c20, 1398px 1740px #f18c20, 401px 571px #f18c20,
    1267px 230px #f18c20, 3px 771px #f18c20, 1663px 1756px #f18c20,
    242px 1814px #f18c20, 1476px 1730px #f18c20, 835px 989px #f18c20,
    1468px 1472px #f18c20, 1027px 595px #f18c20, 1297px 1682px #f18c20,
    1723px 1259px #f18c20, 1040px 276px #f18c20, 896px 79px #f18c20,
    1155px 1695px #f18c20, 831px 244px #f18c20, 139px 638px #f18c20,
    1195px 1518px #f18c20, 4px 1105px #f18c20, 616px 6px #f18c20,
    582px 1230px #f18c20, 1296px 603px #f18c20;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 0 730px 1855px #f18c20, 1517px 892px #f18c20, 331px 717px #f18c20,
    548px 741px #f18c20, 1069px 353px #f18c20, 829px 966px #f18c20,
    1013px 1510px #f18c20, 829px 173px #f18c20, 1049px 184px #f18c20,
    1329px 1378px #f18c20, 16px 1899px #f18c20, 672px 583px #f18c20,
    457px 1058px #f18c20, 1963px 1581px #f18c20, 306px 1285px #f18c20,
    32px 590px #f18c20, 1054px 409px #f18c20, 1398px 768px #f18c20,
    117px 807px #f18c20, 1601px 1439px #f18c20, 1677px 594px #f18c20,
    1466px 430px #f18c20, 708px 869px #f18c20, 1676px 1631px #f18c20,
    1989px 423px #f18c20, 196px 1695px #f18c20, 209px 231px #f18c20,
    260px 704px #f18c20, 885px 1314px #f18c20, 1129px 625px #f18c20,
    1060px 1368px #f18c20, 267px 1943px #f18c20, 423px 1840px #f18c20,
    1391px 24px #f18c20, 16px 1310px #f18c20, 1584px 91px #f18c20,
    1454px 662px #f18c20, 765px 887px #f18c20, 1008px 1787px #f18c20,
    1098px 571px #f18c20, 1722px 397px #f18c20, 1322px 939px #f18c20,
    1158px 537px #f18c20, 912px 387px #f18c20, 1401px 553px #f18c20,
    1914px 1354px #f18c20, 1582px 490px #f18c20, 1550px 1978px #f18c20,
    211px 1945px #f18c20, 1229px 1288px #f18c20, 1031px 118px #f18c20,
    1991px 455px #f18c20, 871px 1675px #f18c20, 842px 1322px #f18c20,
    1764px 1780px #f18c20, 1315px 1595px #f18c20, 1254px 1554px #f18c20,
    387px 132px #f18c20, 1293px 253px #f18c20, 1143px 235px #f18c20,
    524px 1555px #f18c20, 294px 1555px #f18c20, 1280px 515px #f18c20,
    1802px 1729px #f18c20, 939px 974px #f18c20, 1820px 557px #f18c20,
    471px 1822px #f18c20, 445px 1960px #f18c20, 1914px 1208px #f18c20,
    1193px 24px #f18c20, 488px 132px #f18c20, 388px 1247px #f18c20,
    279px 1916px #f18c20, 679px 1856px #f18c20, 310px 1323px #f18c20,
    4px 43px #f18c20, 1838px 114px #f18c20, 1828px 1708px #f18c20,
    394px 1418px #f18c20, 1578px 1770px #f18c20, 1861px 544px #f18c20,
    270px 1743px #f18c20, 717px 1195px #f18c20, 1072px 1093px #f18c20,
    1193px 536px #f18c20, 1155px 1432px #f18c20, 828px 1942px #f18c20,
    1823px 996px #f18c20, 557px 617px #f18c20, 1553px 993px #f18c20,
    1413px 124px #f18c20, 1732px 1474px #f18c20, 1047px 1322px #f18c20,
    680px 862px #f18c20, 297px 313px #f18c20, 40px 1217px #f18c20,
    1830px 939px #f18c20, 1327px 1648px #f18c20, 352px 944px #f18c20,
    240px 124px #f18c20, 1698px 491px #f18c20, 831px 1564px #f18c20,
    890px 1669px #f18c20, 368px 253px #f18c20, 85px 525px #f18c20,
    803px 1847px #f18c20, 1299px 634px #f18c20, 670px 901px #f18c20,
    1490px 121px #f18c20, 1906px 536px #f18c20, 189px 1598px #f18c20,
    1412px 1562px #f18c20, 1801px 1475px #f18c20, 621px 1857px #f18c20,
    805px 1215px #f18c20, 30px 599px #f18c20, 894px 1695px #f18c20,
    1132px 1427px #f18c20, 1896px 1405px #f18c20, 1854px 228px #f18c20,
    1907px 1316px #f18c20, 1792px 377px #f18c20, 355px 1750px #f18c20,
    1023px 558px #f18c20, 280px 994px #f18c20, 1685px 235px #f18c20,
    963px 1324px #f18c20, 1620px 776px #f18c20, 527px 1537px #f18c20,
    747px 908px #f18c20, 192px 119px #f18c20, 1869px 1413px #f18c20,
    1789px 561px #f18c20, 620px 973px #f18c20, 1308px 981px #f18c20,
    49px 868px #f18c20, 742px 1098px #f18c20, 1221px 1927px #f18c20,
    377px 151px #f18c20, 134px 335px #f18c20, 1073px 952px #f18c20,
    503px 1236px #f18c20, 429px 1695px #f18c20, 1452px 805px #f18c20,
    545px 17px #f18c20, 1178px 928px #f18c20, 1909px 1666px #f18c20,
    264px 182px #f18c20, 89px 1899px #f18c20, 1481px 120px #f18c20,
    259px 1800px #f18c20, 1130px 1760px #f18c20, 1207px 324px #f18c20,
    404px 671px #f18c20, 1999px 439px #f18c20, 454px 1219px #f18c20,
    1153px 643px #f18c20, 874px 204px #f18c20, 1918px 710px #f18c20,
    1068px 798px #f18c20, 1734px 947px #f18c20, 375px 1069px #f18c20,
    1422px 1131px #f18c20, 1360px 219px #f18c20, 947px 1043px #f18c20,
    872px 338px #f18c20, 937px 359px #f18c20, 1990px 1484px #f18c20,
    1153px 896px #f18c20, 596px 1269px #f18c20, 164px 32px #f18c20,
    567px 873px #f18c20, 507px 453px #f18c20, 885px 1049px #f18c20,
    1795px 409px #f18c20, 1947px 428px #f18c20, 1006px 367px #f18c20,
    1324px 77px #f18c20, 713px 63px #f18c20, 1717px 1106px #f18c20,
    107px 266px #f18c20, 780px 1746px #f18c20, 1690px 234px #f18c20,
    1685px 804px #f18c20, 368px 554px #f18c20, 15px 1615px #f18c20,
    1047px 1386px #f18c20, 1265px 1550px #f18c20, 48px 273px #f18c20,
    1786px 339px #f18c20, 1033px 1625px #f18c20, 452px 800px #f18c20,
    605px 1523px #f18c20, 1194px 445px #f18c20, 98px 992px #f18c20,
    819px 682px #f18c20, 720px 1875px #f18c20, 1440px 689px #f18c20,
    756px 665px #f18c20, 1793px 255px #f18c20;
  animation: animateStars 30s ease-in-out infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#stars2::after {
  content: " ";
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 0 730px 1855px #f18c20, 1517px 892px #f18c20, 331px 717px #f18c20,
    548px 741px #f18c20, 1069px 353px #f18c20, 829px 966px #f18c20,
    1013px 1510px #f18c20, 829px 173px #f18c20, 1049px 184px #f18c20,
    1329px 1378px #f18c20, 16px 1899px #f18c20, 672px 583px #f18c20,
    457px 1058px #f18c20, 1963px 1581px #f18c20, 306px 1285px #f18c20,
    32px 590px #f18c20, 1054px 409px #f18c20, 1398px 768px #f18c20,
    117px 807px #f18c20, 1601px 1439px #f18c20, 1677px 594px #f18c20,
    1466px 430px #f18c20, 708px 869px #f18c20, 1676px 1631px #f18c20,
    1989px 423px #f18c20, 196px 1695px #f18c20, 209px 231px #f18c20,
    260px 704px #f18c20, 885px 1314px #f18c20, 1129px 625px #f18c20,
    1060px 1368px #f18c20, 267px 1943px #f18c20, 423px 1840px #f18c20,
    1391px 24px #f18c20, 16px 1310px #f18c20, 1584px 91px #f18c20,
    1454px 662px #f18c20, 765px 887px #f18c20, 1008px 1787px #f18c20,
    1098px 571px #f18c20, 1722px 397px #f18c20, 1322px 939px #f18c20,
    1158px 537px #f18c20, 912px 387px #f18c20, 1401px 553px #f18c20,
    1914px 1354px #f18c20, 1582px 490px #f18c20, 1550px 1978px #f18c20,
    211px 1945px #f18c20, 1229px 1288px #f18c20, 1031px 118px #f18c20,
    1991px 455px #f18c20, 871px 1675px #f18c20, 842px 1322px #f18c20,
    1764px 1780px #f18c20, 1315px 1595px #f18c20, 1254px 1554px #f18c20,
    387px 132px #f18c20, 1293px 253px #f18c20, 1143px 235px #f18c20,
    524px 1555px #f18c20, 294px 1555px #f18c20, 1280px 515px #f18c20,
    1802px 1729px #f18c20, 939px 974px #f18c20, 1820px 557px #f18c20,
    471px 1822px #f18c20, 445px 1960px #f18c20, 1914px 1208px #f18c20,
    1193px 24px #f18c20, 488px 132px #f18c20, 388px 1247px #f18c20,
    279px 1916px #f18c20, 679px 1856px #f18c20, 310px 1323px #f18c20,
    4px 43px #f18c20, 1838px 114px #f18c20, 1828px 1708px #f18c20,
    394px 1418px #f18c20, 1578px 1770px #f18c20, 1861px 544px #f18c20,
    270px 1743px #f18c20, 717px 1195px #f18c20, 1072px 1093px #f18c20,
    1193px 536px #f18c20, 1155px 1432px #f18c20, 828px 1942px #f18c20,
    1823px 996px #f18c20, 557px 617px #f18c20, 1553px 993px #f18c20,
    1413px 124px #f18c20, 1732px 1474px #f18c20, 1047px 1322px #f18c20,
    680px 862px #f18c20, 297px 313px #f18c20, 40px 1217px #f18c20,
    1830px 939px #f18c20, 1327px 1648px #f18c20, 352px 944px #f18c20,
    240px 124px #f18c20, 1698px 491px #f18c20, 831px 1564px #f18c20,
    890px 1669px #f18c20, 368px 253px #f18c20, 85px 525px #f18c20,
    803px 1847px #f18c20, 1299px 634px #f18c20, 670px 901px #f18c20,
    1490px 121px #f18c20, 1906px 536px #f18c20, 189px 1598px #f18c20,
    1412px 1562px #f18c20, 1801px 1475px #f18c20, 621px 1857px #f18c20,
    805px 1215px #f18c20, 30px 599px #f18c20, 894px 1695px #f18c20,
    1132px 1427px #f18c20, 1896px 1405px #f18c20, 1854px 228px #f18c20,
    1907px 1316px #f18c20, 1792px 377px #f18c20, 355px 1750px #f18c20,
    1023px 558px #f18c20, 280px 994px #f18c20, 1685px 235px #f18c20,
    963px 1324px #f18c20, 1620px 776px #f18c20, 527px 1537px #f18c20,
    747px 908px #f18c20, 192px 119px #f18c20, 1869px 1413px #f18c20,
    1789px 561px #f18c20, 620px 973px #f18c20, 1308px 981px #f18c20,
    49px 868px #f18c20, 742px 1098px #f18c20, 1221px 1927px #f18c20,
    377px 151px #f18c20, 134px 335px #f18c20, 1073px 952px #f18c20,
    503px 1236px #f18c20, 429px 1695px #f18c20, 1452px 805px #f18c20,
    545px 17px #f18c20, 1178px 928px #f18c20, 1909px 1666px #f18c20,
    264px 182px #f18c20, 89px 1899px #f18c20, 1481px 120px #f18c20,
    259px 1800px #f18c20, 1130px 1760px #f18c20, 1207px 324px #f18c20,
    404px 671px #f18c20, 1999px 439px #f18c20, 454px 1219px #f18c20,
    1153px 643px #f18c20, 874px 204px #f18c20, 1918px 710px #f18c20,
    1068px 798px #f18c20, 1734px 947px #f18c20, 375px 1069px #f18c20,
    1422px 1131px #f18c20, 1360px 219px #f18c20, 947px 1043px #f18c20,
    872px 338px #f18c20, 937px 359px #f18c20, 1990px 1484px #f18c20,
    1153px 896px #f18c20, 596px 1269px #f18c20, 164px 32px #f18c20,
    567px 873px #f18c20, 507px 453px #f18c20, 885px 1049px #f18c20,
    1795px 409px #f18c20, 1947px 428px #f18c20, 1006px 367px #f18c20,
    1324px 77px #f18c20, 713px 63px #f18c20, 1717px 1106px #f18c20,
    107px 266px #f18c20, 780px 1746px #f18c20, 1690px 234px #f18c20,
    1685px 804px #f18c20, 368px 554px #f18c20, 15px 1615px #f18c20,
    1047px 1386px #f18c20, 1265px 1550px #f18c20, 48px 273px #f18c20,
    1786px 339px #f18c20, 1033px 1625px #f18c20, 452px 800px #f18c20,
    605px 1523px #f18c20, 1194px 445px #f18c20, 98px 992px #f18c20,
    819px 682px #f18c20, 720px 1875px #f18c20, 1440px 689px #f18c20,
    756px 665px #f18c20, 1793px 255px #f18c20;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 0 771px 867px #f18c20, 1597px 521px #f18c20, 517px 837px #f18c20,
    1126px 451px #f18c20, 712px 1199px #f18c20, 1483px 1441px #f18c20,
    354px 454px #f18c20, 1960px 232px #f18c20, 1069px 1304px #f18c20,
    1385px 667px #f18c20, 1565px 146px #f18c20, 1136px 537px #f18c20,
    1626px 224px #f18c20, 1951px 278px #f18c20, 1187px 474px #f18c20,
    1642px 969px #f18c20, 149px 1341px #f18c20, 763px 1398px #f18c20,
    1839px 1385px #f18c20, 766px 829px #f18c20, 644px 1394px #f18c20,
    705px 1144px #f18c20, 250px 383px #f18c20, 1976px 778px #f18c20,
    376px 414px #f18c20, 835px 691px #f18c20, 524px 1280px #f18c20,
    1421px 830px #f18c20, 773px 1637px #f18c20, 50px 562px #f18c20,
    98px 499px #f18c20, 1939px 412px #f18c20, 562px 1748px #f18c20,
    250px 1851px #f18c20, 1718px 1114px #f18c20, 686px 416px #f18c20,
    884px 688px #f18c20, 762px 1952px #f18c20, 551px 940px #f18c20,
    1134px 58px #f18c20, 1730px 1874px #f18c20, 1607px 36px #f18c20,
    66px 722px #f18c20, 1744px 363px #f18c20, 1989px 1410px #f18c20,
    603px 132px #f18c20, 1633px 1938px #f18c20, 1919px 715px #f18c20,
    519px 569px #f18c20, 567px 1411px #f18c20, 429px 24px #f18c20,
    274px 605px #f18c20, 1927px 307px #f18c20, 1455px 1612px #f18c20,
    1342px 1654px #f18c20, 779px 1073px #f18c20, 151px 338px #f18c20,
    114px 1665px #f18c20, 481px 9px #f18c20, 1721px 1081px #f18c20,
    1568px 160px #f18c20, 1822px 1341px #f18c20, 1210px 787px #f18c20,
    417px 585px #f18c20, 1198px 1061px #f18c20, 337px 276px #f18c20,
    350px 226px #f18c20, 1865px 1725px #f18c20, 1837px 1571px #f18c20,
    948px 1883px #f18c20, 961px 1028px #f18c20, 1521px 994px #f18c20,
    1466px 1281px #f18c20, 301px 1891px #f18c20, 1511px 1485px #f18c20,
    541px 787px #f18c20, 1651px 1647px #f18c20, 1908px 884px #f18c20,
    1051px 1572px #f18c20, 1836px 336px #f18c20, 1631px 173px #f18c20,
    1230px 1351px #f18c20, 1073px 9px #f18c20, 1301px 276px #f18c20,
    667px 1425px #f18c20, 427px 231px #f18c20, 687px 1774px #f18c20,
    760px 802px #f18c20, 505px 1487px #f18c20, 1173px 288px #f18c20,
    924px 1520px #f18c20, 427px 936px #f18c20, 387px 298px #f18c20,
    693px 1912px #f18c20, 1095px 525px #f18c20, 246px 1397px #f18c20,
    1389px 298px #f18c20, 255px 1497px #f18c20, 1215px 131px #f18c20,
    505px 1705px #f18c20;
  animation: animateStars 31.5s ease-in-out infinite;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

#stars3:after {
  content: " ";
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: 0 771px 867px #f18c20, 1597px 521px #f18c20, 517px 837px #f18c20,
    1126px 451px #f18c20, 712px 1199px #f18c20, 1483px 1441px #f18c20,
    354px 454px #f18c20, 1960px 232px #f18c20, 1069px 1304px #f18c20,
    1385px 667px #f18c20, 1565px 146px #f18c20, 1136px 537px #f18c20,
    1626px 224px #f18c20, 1951px 278px #f18c20, 1187px 474px #f18c20,
    1642px 969px #f18c20, 149px 1341px #f18c20, 763px 1398px #f18c20,
    1839px 1385px #f18c20, 766px 829px #f18c20, 644px 1394px #f18c20,
    705px 1144px #f18c20, 250px 383px #f18c20, 1976px 778px #f18c20,
    376px 414px #f18c20, 835px 691px #f18c20, 524px 1280px #f18c20,
    1421px 830px #f18c20, 773px 1637px #f18c20, 50px 562px #f18c20,
    98px 499px #f18c20, 1939px 412px #f18c20, 562px 1748px #f18c20,
    250px 1851px #f18c20, 1718px 1114px #f18c20, 686px 416px #f18c20,
    884px 688px #f18c20, 762px 1952px #f18c20, 551px 940px #f18c20,
    1134px 58px #f18c20, 1730px 1874px #f18c20, 1607px 36px #f18c20,
    66px 722px #f18c20, 1744px 363px #f18c20, 1989px 1410px #f18c20,
    603px 132px #f18c20, 1633px 1938px #f18c20, 1919px 715px #f18c20,
    519px 569px #f18c20, 567px 1411px #f18c20, 429px 24px #f18c20,
    274px 605px #f18c20, 1927px 307px #f18c20, 1455px 1612px #f18c20,
    1342px 1654px #f18c20, 779px 1073px #f18c20, 151px 338px #f18c20,
    114px 1665px #f18c20, 481px 9px #f18c20, 1721px 1081px #f18c20,
    1568px 160px #f18c20, 1822px 1341px #f18c20, 1210px 787px #f18c20,
    417px 585px #f18c20, 1198px 1061px #f18c20, 337px 276px #f18c20,
    350px 226px #f18c20, 1865px 1725px #f18c20, 1837px 1571px #f18c20,
    948px 1883px #f18c20, 961px 1028px #f18c20, 1521px 994px #f18c20,
    1466px 1281px #f18c20, 301px 1891px #f18c20, 1511px 1485px #f18c20,
    541px 787px #f18c20, 1651px 1647px #f18c20, 1908px 884px #f18c20,
    1051px 1572px #f18c20, 1836px 336px #f18c20, 1631px 173px #f18c20,
    1230px 1351px #f18c20, 1073px 9px #f18c20, 1301px 276px #f18c20,
    667px 1425px #f18c20, 427px 231px #f18c20, 687px 1774px #f18c20,
    760px 802px #f18c20, 505px 1487px #f18c20, 1173px 288px #f18c20,
    924px 1520px #f18c20, 427px 936px #f18c20, 387px 298px #f18c20,
    693px 1912px #f18c20, 1095px 525px #f18c20, 246px 1397px #f18c20,
    1389px 298px #f18c20, 255px 1497px #f18c20, 1215px 131px #f18c20,
    505px 1705px #f18c20;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

@keyframes animateStars {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
    -ms-transform: rotate3d(-1, 1, 0, 0deg);
    -o-transform: rotate3d(-1, 1, 0, 0deg);
    transform: rotate3d(-1, 1, 0, 0deg);
  }

  25% {
    -webkit-transform: rotate3d(-1, 1, 0, 30deg);
    -ms-transform: rotate3d(-1, 1, 0, 30deg);
    -o-transform: rotate3d(-1, 1, 0, 30deg);
    transform: rotate3d(-1, 1, 0, 30deg);
  }

  50% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
    -ms-transform: rotate3d(-1, 1, 0, 0deg);
    -o-transform: rotate3d(-1, 1, 0, 0deg);
    transform: rotate3d(-1, 1, 0, 0deg);
  }

  75% {
    -webkit-transform: rotate3d(-1, 1, 0, -30deg);
    -ms-transform: rotate3d(-1, 1, 0, -30deg);
    -o-transform: rotate3d(-1, 1, 0, -30deg);
    transform: rotate3d(-1, 1, 0, -30deg);
  }

  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
    -ms-transform: rotate3d(-1, 1, 0, 0deg);
    -o-transform: rotate3d(-1, 1, 0, 0deg);
    transform: rotate3d(-1, 1, 0, 0deg);
  }
}

.features-list {
  display: flex;

  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
}

.features-list li {
  -ms-flex: 0 0 50%;
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.features-list li span {
  color: var(--color-900);
  font-size: 16px;
  font-weight: 500;
  display: block;
  padding: 14px 15px;
  border-radius: 5px;
  border-left: 2px solid var(--primary-color);
  background-color: var(--card-color);

  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.wychoosec img {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  display: block;
}

.features-list li span:hover {
  color: #ffffff;
  background-color: var(--primary-color);
}

.feature-sec img.img-fluid.mb-3 {

  margin: 0 auto;
  display: block;
}

.bannersec {
  overflow: hidden;
}

/* aboutsec */

.about-inner {
  background-color: var(--inner-bg);
  height: 100%;
  min-height: 300px;
}

.about-page h2 {
  color: var(--color-900);
}

.about-page p {
  color: var(--color-600);
}

.about-page {
  background-color: var(--bbody-color);
}

.about-page h5 {
  color: var(--color-800);
}

.list-icon-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  left: 24px;
  width: 2px;
  height: 80%;
  border-left: 2px dashed gray;
}

.icon-box .content {
  padding-top: 2px;
}

.list-icon-box {
  margin-top: 41px;
  padding-right: 132px;
  position: relative;
}

.icon-box .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
  background: linear-gradient(294.72deg, #ea9e14 9.05%, #fb841b 79.28%);
  border-radius: 50%;
  margin-right: 17px;
}

.icon-box {
  display: flex;
  margin-bottom: 18px;
  position: relative;
  z-index: 1;
}

.ico-currencies img {
  width: 100%;
  max-width: 60px;
}

.des-currency p {
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  display: block;
}

.transactions h2::after {
  content: "";
  display: block;
  height: 4px;
  background: #f9b707;
  max-width: 50px;
  margin: 5px auto 20px;
  border-radius: 30px;
}

.currencynumbers {
  background-color: var(--inner-bg);
  padding: 30px;
}

.contactus-section {
  background-color: var(--bbody-color);
}

.contactus-section h5 {
  color: var(--color-800);
}

.contactus-section p {
  color: var(--color-600);
}

.contactus-section .form-control {
  border: 1px solid var(--border-color);
}

.contactus-section h2 {
  color: var(--color-900);
}

::placeholder {
  color: var(--color-600) !important
}

.contact-form .card {

  background-color: var(--card-color);
  border: none;
  padding: 10px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form .form-control {
  height: 45px;
  border-radius: 0;
  background-color: var(--form-bg);
}

.contact-form .form-control:focus {
  box-shadow: unset;
  background-color: transparent;
  border-color: var(--primary-color);
}

.form-outline .textareas {
  height: unset !important;
}


.contact-details .card {
  background-color: var(--card-color);
  border: none;
  padding: 10px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.heading-border-line {
  position: relative;
}


.about-inner {
  background-image: url(../../../public/images/home/inner-banner.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: 100%;
  background-position: center;

}


/* footersec */
.footersec {
  background-color: var(--bbody-color);
}

.footercont {
  background-color: var(--foot-color);
}

.footersec span {
  color: var(--color-900);
}

.footersec a {
  color: var(--color-800) !important;
}

.footersec p {
  color: var(--color-600) !important;
}

.footersec h6 {
  color: var(--color-900) !important;
}

.footersec i {
  color: var(--color-900);
}

.navbar-brand {
  color: var(--color-900) !important;
}



/*suagdhevan*/
button.readbtn.btn {
  background-color: var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px;
  text-align: center;
}

.Safe {
  padding-block: 30px;
  padding-inline: 32px;
  box-shadow: 0px 8px 24px rgba(12, 52, 91, 0.04);
  border-radius: 15px;
  background: var(--header-color) !important;

}

.Safe:hover {
  border: 1px solid var(--primary-color) !important;
}

.users {
  color: var(--color-600);
}

.features .single-feature {
  display: flex;
  border: 1px solid rgb(244 160 18);
  justify-content: space-around;
  padding: 20px 0 20px;
  transition: all 0.3s ease-in;
}

.features .single-feature .right .sub-title {
  font-size: 25px;
  line-height: 28px;
  margin-bottom: 2px;
  color: var(--primary-color);
  ;
}

.features .single-feature .right .title {
  font-size: 40px;
  line-height: 60px;
  color: var(--color-900);
  font-weight: 600;
  margin-bottom: 0px;
}

.features .single-feature:hover {
  -webkit-box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  -webkit-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  transform: translateY(-2px);
}

.area-padding-2 {
  padding: 100px 0px 70px;
}

.section-headline {
  padding-bottom: 40px;
  position: relative;
}

.section-headline h3 {
  font-size: 40px;
  line-height: 46px;
  color: var(--color-800) !important;
}

.section-headline p {
  font-size: 16px;
  max-width: 510px;
  margin: 0px auto;
}

.pri_table_list {
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  padding: 40px;
  position: relative;
  overflow: hidden;
  border: 1px solid var(--primary-color);
  margin-bottom: 30px;
  text-align: center;
}

.per-day {
  color: var(--color-800) !important;
  font-size: 24px;
  text-transform: capitalize;
  margin-top: 15px;
  display: block;
  font-weight: 600;
}

.pri_table_list ol {
  list-style: outside none none;
}

.pricing-text {
  padding: 10px 0px;
  display: block;
}

.pri_table_list ol li {
  padding: 5px 0px;
  font-size: 15px;
  color: var(--color-800) !important;

}

.price-btn a {
  color: #fff;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  border-radius: 2px;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 20px;
  background: var(--primary-color);
  display: inline-block;
  border: 1px solid var(--primary-color);
}

.prices {
  font-size: 40px;
  font-weight: 700;
  color: var(--primary-color);
  padding-right: 10px;
  line-height: 40px;
}

footer.footersec.text-center.text-lg-start.text-black {
  background-color: var(--foot-color);
  padding-top: 10px;
}

.Safe img {
  margin: 12px auto;
  display: block;
}

@media (max-width: 768px) {
  .bannersec .shape-image1 img {
    max-width: 176px !important;
  }

  .banner-ico .shape-image1 {
    position: absolute;
    right: 55px;
    top: 27px;
  }

  .features-list {
    display: block;
  }

  .features-list li {
    max-width: 100%;
  }

  .me-counter-box ul li {
    /* display: flex; */
    width: 100% !important;
    /* position: relative; */
    margin: 0 0 16px 0;
  }

  .me-counter-box ul {
    display: block !important;
  }
}

.hesadersec .navbar-brand .img-fluid {
  width: 100%;
  max-width: 80px;
}

.about-inner img {
  width: 100%;
  max-width: 330px;
}

/* Services style*/
.me-about {
  padding-top: 120px;
}

.me-heading {
  margin-bottom: 20px;
}

.me-heading h4 {
  border-left: 4px solid #ffb628;
  padding-left: 10px;
  font-size: 18px;
  color: orange;
}

.me-heading h1,
.me-heading2 h1 {
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--color-900);
}
.me-counter-heading {
  padding: 91px 0px 164px;
  background-image: url(../../../public/images/home/breadcrumb.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  text-align: center;
}

.me-counter-heading h1 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
  z-index: 1;
  position: relative;
}

.me-counter-box {
  background-color: var(--inner-bg);
  padding: 49px 50px;
  margin-top: -70px;
  box-shadow: 1.5px 3.99px 27px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}

.me-about-img img {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  display: block;
}

.me-counter-heading p {
  margin-bottom: 0;
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.me-counter-heading:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

.me-counter-box ul {
  padding: 0px;
  display: flex;
}

.me-counter-box ul li {
  display: flex;
  width: 33%;
  position: relative;
}

.me-counter-box ul li svg {
  width: 55px;
  height: 55px;
  fill: #ffb628;
}

.me-counter-no {
  width: calc(100% - 40px);
  padding-left: 15px;
}

.me-counter-no h4 {
  margin-bottom: 0;
  font-size: 36px;
  display: inline-block;
  line-height: 32px;
  color: var(--color-900);
}

.me-counter-no span {
  color: var(--color-900);

}

.me-counter-box ul li:after {
  position: absolute;
  content: '';
  top: 0;
  right: 70px;
  width: 1px;
  height: 100%;
  background: #eee;
}

/* faq */
.accordion-button {
  background-color: var(--foot-color) !important;
  color: var(--color-900) !important;
}

.accordion-body {
  background-color: var(--bbody-color) !important;
  color: var(--color-900) !important;
}

.accordion-collapse {
  border: 0;
}

.accordion-button:focus {
  box-shadow: unset !important;
}

.accordion-button {
  padding: 0px;
  font-weight: bold;
  border: 0;
  font-size: 18px;
  color: #333333;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.accordion-button:focus {
  box-shadow: none;
  border: none;
}

.accordion-button:not(.collapsed) {
  background: none;
  color: #dc3545;
}

.accordion-body {
  padding: 15px;
  background-color: #f6f6f6;
}

.accordion-button::after {
  width: auto;
  height: auto;
  content: "+";
  font-size: 40px;
  background-image: none;
  font-weight: 100;
  color: #1b6ce5;
  transform: translateY(-4px);
}

.accordion-button:not(.collapsed)::after {
  width: auto;
  height: auto;
  background-image: none;
  content: "-";
  font-size: 48px;
  transform: translate(-5px, -4px);
  transform: rotate(0deg);
}

.me-faq-img img {
  margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 250px;
}

.Token-Details {
  position: relative;
  top: 80px;
  margin-bottom: 120px !important;
}

.Token-Details .card {
  background-color: var(--home-bg);
  border: 1px solid #fbfbfb;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);

}

.Token-Details .card:hover {
  border: 1px solid var(--primary-color);
}

.Token-Details p {
  margin-bottom: 2px;
  font-size: 15px;
}

.Token-Details h5 {
  font-size: 19px;
}
@media (max-width: 1050px){
  .navbar-expand-lg .navbar-toggler {
    display: block !important;
}
}
