/*
* Admin Layout (cryptoon)
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:cryptoon
* Copyright 2022 Pixelwibes
*/

// main layout scss

// left icon navigation bar
.navigation {
    background-color: var(--card-color);
    width: 60px;
    height: 100vh;
    font-size: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    z-index: 9;

    @media only screen and (max-width: $break-medium - 1px) {
        width: 100%;
        height: 60px;
        flex-direction: row;
        align-items: center;
        position: fixed;
        overflow: hidden;

        .brand-icon {
            svg {
                width: 22px;
                margin-right: 20px;
            }
        }

        .navbar-nav {
            justify-content: flex-end !important;
            padding-right: 50px;
            flex-direction: row !important;

            .flex-grow-1 {
                flex-grow: unset !important;
            }

            .nav-link {
                margin-left: 5px;
            }

            .menu-toggle {
                position: fixed;
                right: 6px;
            }
        }
    }
}

// sidebar main div
.sidebar {
    background-color: var(--card-color);
    @include transition(ease .1s);
    z-index: 999;
    border-right: 1px solid var(--border-color);
    padding: 25px !important;
    height: 100vh;
    width: 260px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;

    @media only screen and (max-width: $break-1280 - 1px) {
        position: fixed;
        left: -400px;
        height: 100vh;
        margin: 0px;
        border-radius: 0px;
    }

    @media only screen and (min-width: $break-1280 - 1px) {
        @include c_overflow(scroll);
    }

    @media only screen and (max-width: 375px) {
        height: 100%;
    }

    .form-switch {
        label {
            color: var(--white-color);
            font-size: 15px;
            padding-left: 10px;
        }

        &.theme-rtl {
            padding-left: 2em;
        }
    }

    .sidebar-title {
        color: var(--white-color);
    }

    .menu-list {
        list-style: none;
        padding: 0;

        @media only screen and (max-width: $break-1280 - 1px) {
            @include c_overflow(scroll);
        }

        li[aria-expanded="true"] {
            .m-link {
                //color: var(--secondary-color);
                color: var(--text-color);
            }

            .arrow:before {
                content: "\f10c";
            }

            .sub-menu:before {
                //background-color: var(--secondary-color);
            }
        }

        .m-link,
        .ms-link {
            display: flex;

            &:hover,
            &.active {
                //color: var(--secondary-color);
                color: var(--primary-color);

                &::before {
                    display: block;
                }
            }
        }

        .m-link {
            //color: var(--white-color);
            color: var(--text-color);
            // align-items: center;
            padding: 8px 0;
            font-size: 17px;

            svg {
                margin-right: 10px;
            }

            i {
                color: var(--primary-color);
            }
        }

        .ms-link {
            //color: var(--white-color);
            color: var(--text-color);
            position: relative;
            padding: 5px 0;

            i {
                color: var(--primary-color);
            }

            span {
                font-size: 14px;
            }
        }

        .fa {
            width: 30px;
        }

        .arrow {
            //color: var(--white-color);
            color: var(--text-color);
            opacity: 0.3;
            font-size: 10px;
        }

        .divider {
            color: var(--text-color);
            border-color: var(--border-color) !important;

        }

        [class^="icofont-"] {
            width: 30px;
        }

        .sub-menu {
            list-style: none;
            position: relative;
            padding-left: 45px;

            &::before {
                background-color: var(--white-color);
                content: "";
                position: absolute;
                height: 100%;
                width: 0px;
                left: 8px;
                top: 0;
            }

            .ms-link::before {
                top: 10px;
            }
        }
    }

    .sidebar-mini-btn {
        font-size: 34px;

        &:focus,
        &:active {
            outline: none !important;
            box-shadow: none !important
        }

        .icofont-bubble-right {
            &:before {
                content: "\ea64";
            }
        }

        @media only screen and (max-width: $break-small - 1px) {
            display: none;
        }
    }

    &.gradient {
        background: var(--primary-gradient);

        &.sidebar-mini {
            .menu-list {
                .sub-menu {
                    background: var(--primary-gradient);
                }
            }
        }
    }

    &.sidebar-mini {
        text-align: center;
        padding: 1.5rem 0 !important;
        width: 100px;
        z-index: 9999;

        @media only screen and (min-width: $break-1280 - 1px) {
            overflow: visible;
        }

        .brand-icon {
            display: block;

            .logo-icon {
                margin: 0 auto;
            }

            .logo-text {
                padding-left: 0px;
                padding-top: 8px;
                font-size: 14px;
            }
        }

        .sidebar-title span,
        form {
            display: none;
        }

        .menu-list {
            >li {
                position: relative;

                &:hover {
                    .sub-menu {
                        display: block !important;
                    }
                }
            }

            .m-link {
                margin-bottom: 10px;
                justify-content: center;

                svg {
                    margin-right: 0px;
                }

                >div {
                    display: none;
                }

                span {
                    display: none;
                }
            }

            .ms-link {
                &::before {
                    left: -16px;
                }

                span {
                    font-size: 17px;
                }

                &:hover,
                &.active {
                    color: var(--text-color);
                }
            }

            .fa {
                width: 100%;
            }

            .sub-menu {
                background: var(--primary-color);
                border-radius: .25rem;
                box-shadow: 0 10px 40px 10px rgba($dark, .2);
                width: 200px;
                padding-top: 10px;
                padding-bottom: 10px;
                position: absolute;
                left: 60px;
                top: 0;
                padding-left: 15px;
                z-index: 9;

                &:before {
                    display: none;
                }

                .ms-link::before {
                    top: 12px;
                }
            }

            .divider small {
                display: none;
            }

            [class^="icofont-"] {
                width: 100%;
            }
        }

        .fa-arrow-left::before {
            content: "\f061";
        }

        #menu-Components {
            width: 300px;

            li {
                width: 50%;
                float: left;
            }
        }

        #menu-Authentication {
            width: 230px;
        }

        .sub-menu.show {
            display: none;
        }

        .sidebar-mini-btn {
            .icofont-bubble-right {
                &:before {
                    content: "\ea65";
                }
            }
        }

        .form-switch {
            label {
                display: none;
            }

            &.theme-rtl {
                padding-left: 2.5em;
            }
        }
    }

    &.open {
        left: 0px;
        background-color: var(--card-color);

        @media only screen and (max-width: $break-medium - 1px) {
            left: 0;
        }
    }

    // Logo layout scss
    .brand-icon {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 6px 18px;

        .logo-icon {
            i {
                // color: $white;
                color: var(--text-color);
            }

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 50px;

                path {
                    fill: var(--primary-color);
                }

                @media only screen and (max-width: $break-small - 1px) {
                    width: 25px;
                }
            }

            @media only screen and (max-width: $break-small - 1px) {
                width: 40px;
                height: 40px;
                min-width: 40px;
                min-height: 40px;
                line-height: 40px;
            }
        }

        .logo-text {
            font-weight: bold;
            //color: var(--white-color);
            color: var(--text-color);
            font-size: 18px;
            display: inline-block;
            padding-left: 15px;

            @media only screen and (max-width: $break-small - 1px) {
                font-size: 17px;
            }
        }
    }

}

.menu-toggle {
    color: var(--text-color);

    @media only screen and (min-width: $break-1280) {
        display: none;
    }
}

// project main body part
.main {
    background-color: var(--body-color);
    @include c_overflow(scroll);
    height: 100vh;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;

    .body {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
    }
}


// main header search bar
.main-search {
    position: relative;
    border-color: rgba($text-color, 0.1) !important;

    .form-control {
        border-color: transparent;
        background-color: transparent;
        padding-left: 0;
        border-radius: 0;

        &:focus,
        &:active {
            box-shadow: none;
            color: var(--primary-color);
            border-bottom: 1px solid rgba($text-color, 0.3);

            &::placeholder {
                color: rgba($text-color, 0.7);
            }
        }

        &::placeholder {
            color: rgba($text-color, 0.4);
        }
    }

    .search-result {
        position: absolute;
        width: 100%;
        top: 40px;
        z-index: 9;

        .dropdown-item {
            color: var(--color-600);

            &:hover {
                color: var(--primary-color);
                background-color: rgba(var(--primary-rgb), .1);
            }
        }

        @media only screen and (max-width: $break-medium - 1px) {
            position: fixed;
            width: calc(100% - 32px);
            margin: 0 1rem;
            top: 68px;
            left: 0;
        }
    }
}

//Top Menu Auth flow
.auth-flow {
    a {
        display: block;
        position: relative;
        min-width: 9.22rem;
        padding: 3px 25px;

        &::before {
            -moz-transition: ease 0.3s;
            -o-transition: ease 0.3s;
            -webkit-transition: ease 0.3s;
            transition: ease 0.3s;
            content: '\eaae';
            font-family: 'IcoFont';
            position: absolute;
            left: 0;
            font-size: 18px;
            top: 1px;
        }

        &:hover {
            &:before {
                left: 3px;
            }
        }
    }
}

/***********notifications*********/
.notifications {

    .pulse,
    .pulse:focus {
        color: var(--text-color) !important;
    }

    .card {
        background-color: var(--card-color);

        .card-header {
            border-bottom: 1px solid var(--border-color);
            background-color: var(--card-color1);
            color: var(--color-900);

            .nav-tabs {

                .nav-link {
                    color: rgba($white, .2);
                    font-size: 1rem;
                    border: 0;

                    &.active,
                    &:hover {
                        color: var(--secondary-color);
                        background-color: transparent;

                        &::after {
                            content: '';
                            width: 7px;
                            height: 7px;
                            background-color: var(--secondary-color);
                            display: block;
                            border-radius: 50%;
                            margin: 5px auto 0;
                        }
                    }
                }
            }
        }

        .list {
            @include overflow(scroll);
            height: 330px;
            overflow-y: auto;
        }
    }

    .dropdown-menu {
        .w380 {
            @media only screen and (max-width: $break-small - 1px) {
                width: 320px;
            }

            @media only screen and (max-width: 380px) {
                width: 280px;
            }
        }
    }
}

/***********Header-icon-set*********/
.h-right {
    @media only screen and (max-width: 1279px) {
        margin-left: auto;
        padding-right: 15px;
    }

    @media only screen and (max-width: 767px) {
        margin-left: unset;
        padding-right: unset;
    }

    @media only screen and (max-width: 500px) {
        width: 348px;
        justify-content: end;
    }

    @media only screen and (max-width: 400px) {
        width: auto;
        margin-left: auto;
        padding-right: 12px;
    }

    .user-profile {
        .u-info {
            @media only screen and (max-width: 500px) {
                display: none;
            }
        }


    }

    .notifications {
        @media (max-width: 480px) {
            display: none;
        }
    }

    .nav-link {
        text-decoration: none;
        outline: none;
    }
}

/***********svg-icon-Color*********/
.st0 {
    fill: var(--svg-color);
}

.st1 {
    fill: url(#crp_svg);
}

.st2 {
    stop-color: var(--primary-color);
}

.st3 {
    stop-color: var(--chart-color4);
}

.st4 {
    fill: var(--primary-color);
}

/***********Color-picker*********/
.sketch-picker {
    position: absolute;
    z-index: 999;
    top: 30%;
}

// Header

.sidebar .menu-list li {
    padding: 8px 5px;
}


.dropdown-toggle::after {
    display: none !important;
}

.user-header nav.nav.navbar.navbar-expand-lg {
    border-bottom: 1px solid var(--border-color);
}

.user-header .nav-link {
    background-color: unset !important;
}


.Exchangesidebar {
    background-color: var(--body-color);
    @include transition(ease .1s);
    z-index: 999;

    height: 100vh;
    width: 100%;
    max-width: 14.2rem;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;

    @media only screen and (max-width: 2560px) {
        position: fixed;
        left: -400px;
        height: 100vh;
        margin: 0px;
        border-radius: 0px;
    }

    @media only screen and (max-width: ($break-1280 - 1px)) {
        position: fixed;
        left: -400px;
        height: 100vh;
        margin: 0px;
        border-radius: 0px;
    }

    @media only screen and (min-width: ($break-1280 - 1px)) {
        @include c_overflow(scroll);
    }

    @media only screen and (max-width: 375px) {
        height: 100%;
    }

    .form-switch {
        label {
            color: var(--white-color);
            font-size: 15px;
            padding-left: 10px;
        }

        &.theme-rtl {
            padding-left: 2em;
        }
    }

    .sidebar-title {
        color: var(--white-color);
    }

    .menu-list {
        list-style: none;
        padding: 0;

        @media only screen and (max-width: ($break-1280 - 1px)) {
            @include c_overflow(scroll);
        }

        li[aria-expanded="true"] {
            .m-link {
                //color: var(--secondary-color);
                color: var(--text-color);
            }

            .arrow:before {
                content: "\f10c";
            }

            .sub-menu:before {
                //background-color: var(--secondary-color);
            }
        }

        .m-link,
        .ms-link {
            display: flex;
            padding: 15px 16px;
            color: var(--text-color);
            font-size: 0.9rem;
            padding-left: 1.25rem;

            font-weight: 500;
            align-items: center;

            &:hover,
            &.active {
                //color: var(--secondary-color);
                background-color: var(--card-hover);
                border-radius: 4px;
                width: 100%;
                color: var(--secondary-color);

                &::before {
                    display: block;
                }
            }
        }


        .ms-link {
            //color: var(--white-color);
            color: var(--text-color);
            position: relative;
            padding: 5px 0;

            i {
                color: var(--primary-color);
            }

            span {
                font-size: 14px;
            }
        }

        .fa {
            width: 30px;
        }

        .arrow {
            //color: var(--white-color);
            color: var(--text-color);
            opacity: 0.3;
            font-size: 10px;
        }

        .divider {
            color: var(--text-color);
            border-color: var(--border-color) !important;

        }

        [class^="icofont-"] {
            width: 30px;
        }

        .sub-menu {
            list-style: none;
            position: relative;
            padding-left: 45px;

            &::before {
                background-color: var(--white-color);
                content: "";
                position: absolute;
                height: 100%;
                width: 0px;
                left: 8px;
                top: 0;
            }

            .ms-link::before {
                top: 10px;
            }
        }
    }

    .sidebar-mini-btn {
        font-size: 34px;

        &:focus,
        &:active {
            outline: none !important;
            box-shadow: none !important
        }

        .icofont-bubble-right {
            &:before {
                content: "\ea64";
            }
        }

        @media only screen and (max-width: ($break-small - 1px)) {
            display: none;
        }
    }

    &.gradient {
        background: var(--primary-gradient);

        &.sidebar-mini {
            .menu-list {
                .sub-menu {
                    background: var(--primary-gradient);
                }
            }
        }
    }

    &.sidebar-mini {
        text-align: center;
        padding: 1.5rem 0 !important;
        width: 100px;
        z-index: 9999;

        @media only screen and (min-width: ($break-1280 - 1px)) {
            overflow: visible;
        }

        .brand-icon {
            display: block;

            .logo-icon {
                margin: 0 auto;
            }

            .logo-text {
                padding-left: 0px;
                padding-top: 8px;
                font-size: 14px;
            }
        }

        .sidebar-title span,
        form {
            display: none;
        }

        .menu-list {
            list-style: none;
            padding: 0;

            @media only screen and (max-width: ($break-1280 - 1px)) {
                @include c_overflow(scroll);
            }

            li[aria-expanded="true"] {
                .m-link {
                    //color: var(--secondary-color);
                    color: var(--text-color);
                }

                .arrow:before {
                    content: "\f10c";
                }

                .sub-menu:before {
                    //background-color: var(--secondary-color);
                }
            }

            .m-link,
            .ms-link {
                display: flex;

                &:hover,
                &.active {
                    //color: var(--secondary-color);
                    color: var(--primary-color);

                    &::before {
                        display: block;
                    }
                }
            }

            .m-link {
                //color: var(--white-color);

                // align-items: center;



                svg {
                    margin-right: 10px;
                }

                i {
                    color: var(--primary-color);
                }
            }

            .ms-link {
                //color: var(--white-color);
                color: var(--text-color);
                position: relative;
                padding: 5px 0;

                i {
                    color: var(--primary-color);
                }

                span {
                    font-size: 14px;
                }
            }

            .fa {
                width: 30px;
            }

            .arrow {
                //color: var(--white-color);
                color: var(--text-color);
                opacity: 0.3;
                font-size: 10px;
            }

            .divider {
                color: var(--text-color);
                border-color: var(--border-color) !important;

            }

            [class^="icofont-"] {
                width: 30px;
            }

            .sub-menu {
                list-style: none;
                position: relative;
                padding-left: 45px;

                &::before {
                    background-color: var(--white-color);
                    content: "";
                    position: absolute;
                    height: 100%;
                    width: 0px;
                    left: 8px;
                    top: 0;
                }

                .ms-link::before {
                    top: 10px;
                }
            }
        }

        .fa-arrow-left::before {
            content: "\f061";
        }

        #menu-Components {
            width: 300px;

            li {
                width: 50%;
                float: left;
            }
        }

        #menu-Authentication {
            width: 230px;
        }

        .sub-menu.show {
            display: none;
        }

        .sidebar-mini-btn {
            .icofont-bubble-right {
                &:before {
                    content: "\ea65";
                }
            }
        }

        .form-switch {
            label {
                display: none;
            }

            &.theme-rtl {
                padding-left: 2.5em;
            }
        }
    }

    &.open {
        left: 0px;
        background-color: var(--card-color);

        @media only screen and (max-width: ($break-medium - 1px)) {
            left: 0;
            padding: 24px 0 !important;

        }
    }

    // Logo layout scss
    .brand-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        .logo-icon {
            i {
                // color: $white;
                color: var(--text-color);
            }

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 50px;

                path {
                    fill: var(--primary-color);
                }

                @media only screen and (max-width: ($break-small - 1px)) {
                    width: 25px;
                }
            }

            @media only screen and (max-width: ($break-small - 1px)) {
                width: 100%;
                height: auto;
                max-width: 150px;

            }
        }

        .logo-text {
            font-weight: bold;
            //color: var(--white-color);
            color: var(--text-color);
            font-size: 18px;
            display: inline-block;
            padding-left: 15px;

            @media only screen and (max-width: ($break-small - 1px)) {
                font-size: 17px;
            }
        }
    }

}

.Exchangesidebar .menu-list .m-link h6 {
    margin: 0 10px !important;
    font-weight: 500 !important;
}

.Exchangesidebar .menu-list .m-link svg {
    fill: var(--secondary-color) !important;
    stroke-width: 0px;
}


// trade
.h-right {
    margin-right: 20px;
}

.navbar-toggler:focus {
    box-shadow: unset !important;
}

span.fa.fa-bars {
    color: var(--color-900);
}

.sidebar.open ul li {
    padding: 0;
}

.sidebar.open {
    padding: 0 !important;
}

.Exchangesidebar .brand-icon {
    padding: 10px 10px !important;
}


.searchbtnss {
    background-color: var(--primary-color);
    border: none;
    padding: 11px 24px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.user-header img.img-fluid {
    width: 100%;
    max-width: 80px;
}

.user-header .dropdown.notifications .dropdown-menu {
    inset: 43px auto auto -200px !important;
    width: 100%;
    min-width: 300px;
}

.user-header .dropdown.notifications .dropdown-menu svg {
    fill: var(--primary-color);
}

.dropdown-divider {
    border-top: 1px solid var(--border-color) !important;
}

.user-profile .dropdown-menu .card {
    background-color: var(--card-color);
}

.list-groups svg {
    fill: var(--primary-color);
    margin: 0 10px;
}

.list-groups a {
    color: var(--color-900);
}

.list-groups a:hover {
    color: var(--color-900);
}

.user-profile p {
    color: var(--color-900);
}

.user-profile span {
    color: var(--color-600);

}

.user-profile .dropdown-menu .card {
    width: 100%;
    background-color: var(--card-color1);
    border-radius: 0;
    min-width: 250px;
}
.user-profile a{
    color: var(--color-900);
    font-size: 17px;
    font-weight: 500;
}
.user-profile a:hover{
    color: var(--primary-color);
}
.user-profile .dropdown-menu {
    inset: 45px 0px 30px -165px !important;
}

.viewal {
    color: var(--primary-color);
}

.logo-img img {
    width: 100%;
    max-width: 80px;
}

.user-header .navbar.navbar-expand-lg {
    border-bottom: 3px solid var(--border-color);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    position: fixed;
    width: 100%;
    background-color: var(--card-color);
    z-index: 999;
}
.user-header .navbar-expand-lg .navbar-nav .nav-link{
    color: var(--color-900);
    font-size: 17px;
    font-weight: 500;
    padding: 0 18px;
    
}
.user-header .navbar-expand-lg .navbar-nav .nav-link:hover{
    color: var(--primary-color);
}

